import { cloneElement, ReactElement, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import api from "../utils/api";

interface LoadingContainerProps {
  children: ReactElement,
  url: string,
}
function LoadingContainer({ children, url }:LoadingContainerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const {data: value, success} = await api.get(url);
      if(success) {
        setData(value);
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    loadData();
  }, [url]);

  if(isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Spinner animation="border" style={{ width: "3rem", height: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }

  return cloneElement(children, data);
}

export default LoadingContainer;
