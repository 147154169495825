// Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
// Hooks
import { useNavigate } from "react-router-dom";
// Assets
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function BackButton() {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon={faChevronLeft}/>
    </Button>
  )
}

export default BackButton;
