// Components
import Button from "react-bootstrap/Button";
import LoadingContainer from "../../../components/LoadingContainer";
import RoastRow from "../components/RoastRow";
import Text from "../../../components/Text";
// Types
import { CoffeeRoastDB, CoffeeStatsDB, CoffeeUserPreferences, DEFAULT_COFFEE_STATS, DEFAULT_USER_PREFERENCES } from "../../../@types/coffee";
// Hooks
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface CoffeeRoastsProps {
  coffee_roasts: CoffeeRoastDB[],
  coffee_stats: CoffeeStatsDB,
  user_preferences: CoffeeUserPreferences, 
  refreshCoffeeRoasts: () => void,
}
function CoffeeRoasts({coffee_roasts, coffee_stats, user_preferences, refreshCoffeeRoasts}:CoffeeRoastsProps) {
  const navigate = useNavigate();

  return (
    <div className="container h-100 d-flex flex-column">
      <Text size={4} className="mb-2">Coffee Roasts</Text>
      <div className="bg-body sticky-top">
        <hr className="mt-0 mb-2" />
        <div className="row justify-content-between">
          <div className="col-4 d-flex flex-column justify-content-start align-items-center">
            <p className="m-0 text-muted text-center">Roasts</p>
            <p className="m-0 fw-bold text-center">{coffee_stats.num_roasts}</p>
          </div>
          <div className="col-4 d-flex flex-column justify-content-start align-items-center">
            <p className="m-0 text-muted text-center">Beans</p>
            <p className="m-0 fw-bold text-center">{coffee_stats.num_beans}</p>
          </div>
          <div className="col-4 d-flex flex-column justify-content-start align-items-center cursor-pointer"
            onClick={() => navigate('/coffee/bags')}
          >
            <p className="m-0 text-muted text-center text-decoration-underline">Bags</p>
            <p className="m-0 fw-bold text-center">{coffee_stats.num_bags}</p>
          </div>
        </div>
        <Button className="mt-2 w-100" onClick={() => navigate('/coffee/roasts/new')}>New Roast</Button>      
        <hr className="my-2" />
      </div>
      <div className="mt-2 mb-3">
        {coffee_roasts.map((coffee_roast) => (
          <RoastRow key={`coffee-roast-${coffee_roast.coffee_roast_id}`} 
            coffee_roast={coffee_roast} onRowClick={() => {}} refreshCoffeeRoasts={refreshCoffeeRoasts} user_preferences={user_preferences} />
        ))}
      </div>
    </div>
  )
}

function CoffeeRoastsWrapper() {
  // random number to force refresh
  const [rand, setRand] = useState(0);

  const getRand = () => {
    return Math.floor(Math.random() * 100) + 1;
  }

  const refreshCoffeeRoasts = () => {
    let new_rand = getRand();
    while(new_rand === rand) {
      new_rand = getRand();
    }
    setRand(new_rand);
  }

  return (
    <LoadingContainer url={`/coffee/roasts?r=${rand}`}>
      <CoffeeRoasts coffee_roasts={[]} coffee_stats={DEFAULT_COFFEE_STATS} refreshCoffeeRoasts={refreshCoffeeRoasts} user_preferences={DEFAULT_USER_PREFERENCES} />
    </LoadingContainer>
  )
}

export default CoffeeRoastsWrapper;
