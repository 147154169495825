// Components
import Modal from "react-bootstrap/Modal";
import Text from "../../../components/Text";
import RoastInput from "./RoastInput";
// Types
import { ModalProps } from "../../../@types/modal";
import { RoastInputType } from "../../../@types/coffee";

interface RoastInputModalProps extends ModalProps {
  onSubmit: ({value, type}: {value: number, type: RoastInputType}) => void,
}
function RoastInputModal({show, onHide, onSubmit}:RoastInputModalProps) {
  return (
    <Modal show={show} fullscreen={"md-down"} onHide={onHide}>
      <Modal.Header className='border-0 pb-0' closeButton closeVariant='white'>
        <Text size={4}>Roast Input</Text>
      </Modal.Header>
      <Modal.Body>
        <RoastInput show={show} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  )
}

export default RoastInputModal;
