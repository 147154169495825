// Hooks
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useBreakpoint from "../../../hooks/useBreakpoint";
// Components
import LoadingContainer from "../../../components/LoadingContainer";
import Button from "react-bootstrap/Button";
import Text from "../../../components/Text";
import RoastTimer from "../components/RoastTimer";
import RoastInputModal from "../components/RoastInputModal";
import RoastTimeline from "../components/RoastTimeline";
import RoastDetailsModal from "../components/RoastDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoastButtons from "../components/RoastButtons";
// Types
import { CoffeeRoastDB, CoffeeUserPreferences, DEFAULT_COFFEE_ROAST, DEFAULT_USER_PREFERENCES, RoastInputType } from "../../../@types/coffee";
// Utils
import api from "../../../utils/api";
// Assets
import { faChartLine, faChevronLeft, faInfo, faTable } from "@fortawesome/free-solid-svg-icons";
import RoastProfile from "../components/RoastProfile";
import RoastInput from "../components/RoastInput";
import { Breakpoint } from "../../../@types/breakpoint";
import RoastInputsTable from "../components/RoastInputsTable";

interface CoffeeRoastProps {
  coffee_roast: CoffeeRoastDB,
  user_preferences: CoffeeUserPreferences,
};
function CoffeeRoast({coffee_roast, user_preferences}:CoffeeRoastProps) {
  const [coffeeRoast, setCoffeeRoast] = useState<CoffeeRoastDB>(DEFAULT_COFFEE_ROAST);
  const [templateRoast, setTemplateRoast] = useState<CoffeeRoastDB|null>(null);
  const [view, setView] = useState('profile');

  const postUpdate = async (body: any) => {
    const {success, data} = await api.post(`/coffee/roasts/${coffee_roast.coffee_roast_id}`, body);
    if(success) {
      const {coffee_roast: updated_coffee_roast} = data;
      setCoffeeRoast({...updated_coffee_roast});
    }
  }

  const toggleTemplateRoast = async () => {
    if(templateRoast) {
      setTemplateRoast(null);
      return;
    }

    const {success, data} = await api.get(`/coffee/roasts/template`);
    if(success) {
      const {coffee_roast: template_roast} = data;
      setTemplateRoast(template_roast);
    }
  }

  useEffect(() => {
    setCoffeeRoast({...coffee_roast});
  }, [coffee_roast]);

  const addInput = async ({value, type}: {value: number, type: RoastInputType}) => {
    postUpdate({input: {
      input_value: value,
      input_type: type,
    }});
    setView('profile');
  }

  const toggleRoastInput = () => {
    if(view === 'input') {
      setView('profile');
    } else {
      setView('input');
    }
  }

  return (
    <>
      <div className="container h-100 d-flex flex-column">
        <CoffeeRoastHeader coffee_roast={coffeeRoast} 
          postUpdate={postUpdate}
          templateLoaded={!!templateRoast}
          toggleTemplateRoast={toggleTemplateRoast}
        />
        <div className="row h-100">
          <div className="col-12 col-md-5" onClick={() => setView('profile')}>
            <RoastTimer start_time={coffeeRoast.start_time} end_time={coffeeRoast.end_time} />
            <div className="d-none d-md-block">
              <RoastTimeline start_time={coffeeRoast.start_time} end_time={coffeeRoast.end_time} history={coffeeRoast.input_history} />
            </div>
          </div>
          <div className="col-12 col-md-7">
            <CoffeeRoastBody 
              coffee_roast={coffeeRoast} 
              template_roast={templateRoast}
              user_preferences={user_preferences}
              view={view}
              setView={setView}
              addInput={addInput}
              setCoffeeRoast={setCoffeeRoast}
            />
          </div>
        </div>
        {!coffeeRoast.end_time && (
          <RoastButtons 
            coffee_roast={coffeeRoast} 
            postUpdate={postUpdate}
            showInput={view === 'input'}
            toggleRoastInput={toggleRoastInput} 
          />
        )}
      </div>
    </>
  );
}

interface CoffeeRoastHeaderProps {
  coffee_roast: CoffeeRoastDB,
  postUpdate: (body: any) => void,
  templateLoaded: boolean,
  toggleTemplateRoast: () => void,
}
function CoffeeRoastHeader({coffee_roast, postUpdate, templateLoaded, toggleTemplateRoast}:CoffeeRoastHeaderProps) {
  const navigate = useNavigate();

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Button onClick={() => navigate('/coffee/roasts')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Text size={6} className="px-2 text-center">{coffee_roast.coffee_bean_name}</Text>
        <Button
          onClick={() => setShowDetailsModal(true)}
          variant="primary"
        >
          <FontAwesomeIcon className="px-2" icon={faInfo} />
        </Button>
      </div>
      <RoastDetailsModal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        coffee_roast={coffee_roast}
        postUpdate={postUpdate}
        templateLoaded={templateLoaded}
        toggleTemplate={() => {
          toggleTemplateRoast();
          setShowDetailsModal(false);
        }}
      />
    </>
  )
}

interface CoffeeRoastBodyProps {
  coffee_roast: CoffeeRoastDB,
  template_roast: CoffeeRoastDB|null,
  user_preferences: CoffeeUserPreferences,
  view: string,
  setView: (str: string) => void,
  addInput: (params: {value: number, type: RoastInputType}) => void,
  setCoffeeRoast: React.Dispatch<React.SetStateAction<CoffeeRoastDB>>,
}
function CoffeeRoastBody({coffee_roast, template_roast, user_preferences, view, setView, addInput, setCoffeeRoast}:CoffeeRoastBodyProps) {
  const breakpoint = useBreakpoint();

  if(view === 'input') {
    return (
      breakpoint > Breakpoint.MD ? <RoastInputModal show={view === 'input'} onHide={() => setView('profile')} onSubmit={addInput}/> : <RoastInput show={view === 'input'} onSubmit={addInput} /> 
    )
  } else if(view === 'table') {
    return (
      <div>
        <div className="d-flex justify-content-end my-2">
          <Button onClick={() => setView('profile')}>
            <FontAwesomeIcon icon={faChartLine}/>
          </Button>
        </div>
        <RoastInputsTable coffee_roast={coffee_roast} setCoffeeRoast={setCoffeeRoast} />
      </div>
    )
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-end mt-2">
        <Text size={6}>Roast Profile</Text>
        <Button onClick={() => setView('table')}>
          <FontAwesomeIcon icon={faTable} />
        </Button>
      </div>
      <RoastProfile coffee_roast={coffee_roast} template_roast={template_roast} user_preferences={user_preferences} />
    </>
  )
}

function CoffeeRoastWrapper() {
  const {coffee_roast_id} = useParams();

  return (
    <LoadingContainer url={`/coffee/roasts/${coffee_roast_id}`}>
      <CoffeeRoast coffee_roast={DEFAULT_COFFEE_ROAST} user_preferences={DEFAULT_USER_PREFERENCES} />
    </LoadingContainer>
  )
}

export default CoffeeRoastWrapper;
