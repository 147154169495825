import Table from "react-bootstrap/Table";
import { CoffeeRoastDB, RoastInput, RoastInputType } from "../../../@types/coffee";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ServerInputField from "../../../components/ServerInputField";
import Text from "../../../components/Text";
import api from "../../../utils/api";

interface RoastInputsTableProps {
  coffee_roast: CoffeeRoastDB,
  setCoffeeRoast: React.Dispatch<React.SetStateAction<CoffeeRoastDB>>,
}
export default function RoastInputsTable({coffee_roast, setCoffeeRoast}:RoastInputsTableProps) {
  const [editing, setEditing] = useState(false);
  const [editInput, setEditInput] = useState<RoastInput|null>(null);
  const [editType, setEditType] = useState<RoastInputType>(RoastInputType.BEAN_TEMP);
  const [editValue, setEditValue] = useState('');

  const selectRow = (input: RoastInput) => {
    setEditing(true);
    setEditValue(`${input.value}`);
    setEditType(input.type);
    setEditInput(input);
  }

  const postUpdate = async (body: object) => {
    if(!editInput) {
      return;
    }
    const {data, success} = await api.patch(`/coffee/roasts/${coffee_roast.coffee_roast_id}/inputs/${editInput.input_id}`, body);
    if(success) {
      const {coffee_roast} = data;
      setCoffeeRoast({...coffee_roast});
    }
  }

  const updateInput = async () => {
    postUpdate({value: editValue});
    setEditing(false);
  }

  const updateType = (type: RoastInputType) => {
    postUpdate({type});
    setEditType(type);
  }

  if(editing) {
    return (
      <div>
        <div className="d-flex mb-3">
          <Text size={6}>Update {editInput?.input_id}</Text>
        </div>
        <ButtonGroup className="mb-3 w-100">
          <Button 
            onClick={() => updateType(RoastInputType.BEAN_TEMP)}
            variant={editType === RoastInputType.BEAN_TEMP ? 'primary' : 'outline-primary'}>BEAN_TEMP</Button>
          <Button 
            onClick={() => updateType(RoastInputType.POWER_DRAW)}
            variant={editType === RoastInputType.POWER_DRAW ? 'primary' : 'outline-primary'}>POWER_DRAW</Button>
        </ButtonGroup>
        <ServerInputField
          value={editValue}
          setValue={setEditValue}
          type="text"
          onClick={updateInput}
          btnTitle="Update"
        />
        <div className="d-flex mt-3">
          <Button variant='outline-tertiary' onClick={() => {
            setEditing(false);
          }}>Cancel</Button>
        </div>
      </div>
    )
  }

  return (
    <div className="max-h-500px overflow-scroll"> 
      <Table striped bordered hover size="md">
        <thead>
          <tr>
            <th>#</th>
            <th>Type</th>
            <th>Value</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {coffee_roast.input_history.map((input) => (
            <tr key={`${coffee_roast.coffee_roast_id}-${input.input_id}`}>
              <td className="align-middle">{input.input_id}</td>
              <td className="align-middle">{input.type}</td>
              <td className="align-middle">{input.value}</td>
              <td className="text-center">
                <Button onClick={() => selectRow(input)}>
                  <FontAwesomeIcon icon={faEdit}/>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}