export const hasPermission = (permission: string, acl: string) => {
  if(acl === 'colin') {
    return true;
  }
  const acls = acl?.split('|') || [];
  return acls.includes(permission);
}

export const toReadablePhone = (phone:string) => {
  return phone.slice(0, 2) 
    + " (" 
    + phone.slice(2, 5)
    + ") "
    + phone.slice(5, 8)
    + "-"
    + phone.slice(8);
}

// Marko-like Class Parsing for React
export const classNames = (classes: any): string => {
  if(typeof classes === 'string') {
    return classes;
  } else if(Array.isArray(classes)) {
    return classes.map(c => classNames(c)).filter(Boolean).join(' ').trim();
  } else if(typeof classes === 'object' && classes !== null) {
    return Object.entries(classes).map(([key, value]) => {
      if(typeof key === 'string' && value) {
        return key;
      }
      return null;
    }).filter(Boolean).join(' ').trim();
  } else {
    return '';
  }
}

interface CustomTime {
  minutes: number,
  seconds: number,
}
export const convertSeconds = (seconds: number): CustomTime => {
  return {
    minutes: Math.floor(seconds / 60),
    seconds: (seconds % 60),
  }
}
export const timeSince = (time: string|null): CustomTime => {
  if(!time) {
    return {minutes: 0, seconds: 0};
  }
  const time_since = Date.now() - Date.parse(time); 
  const minutes = Math.floor((time_since / 1000 / 60) % 60);
  const seconds = Math.floor((time_since / 1000) % 60);
  return {minutes, seconds};
}
export const timeBetween = (start: string|Date, end: string|Date): CustomTime => {
  if(!start || !end) {
    return {minutes: 0, seconds: 0};
  }
  const start_time = typeof start === 'string' ? new Date(start) : start;
  const end_time = typeof end === 'string' ? new Date(end) : end;
  const time_since = (end_time.getTime()) - (start_time.getTime()); 
  const minutes = Math.floor((time_since / 1000 / 60) % 60);
  const seconds = Math.floor((time_since / 1000) % 60);
  return {minutes, seconds};
}
export const getDisplayTime = ({minutes, seconds}:CustomTime): string => {
  return `${minutes.toString().padStart(2, '0')}:${Math.abs(seconds).toString().padStart(2, '0')}`;
}

export const celsiusToFahrenheit = (celsius: number): number => {
  // (0°C × 9/5) + 32 = 32°F
  return (celsius * (9 / 5)) + 32;
}
