import { useParams } from "react-router-dom";
import { CoffeeBagDB, CoffeeRoastDB, CoffeeUserPreferences, DEFAULT_COFFEE_BAG, DEFAULT_USER_PREFERENCES } from "../../../@types/coffee";
import LoadingContainer from "../../../components/LoadingContainer";
import Text from "../../../components/Text";
import BackButton from "../../../components/BackButton";
import RoastRow from "../components/RoastRow";

interface CoffeeBagProps {
  coffee_bag: CoffeeBagDB,
  coffee_roasts: CoffeeRoastDB[],
  user_preferences: CoffeeUserPreferences,
}
function CoffeeBag({ coffee_bag, coffee_roasts, user_preferences }:CoffeeBagProps) {
  return (
    <div className="container h-100 d-flex flex-column">
      <div className="d-flex align-items-center mb-2">
        <BackButton />
        <Text size={4} className="ms-2">{coffee_bag.bag_code}</Text>      
      </div>
      <hr className="my-0" />
      <div className="mt-2 mb-3">
        {coffee_roasts.map((coffee_roast) => (
          <RoastRow key={`coffee-roast-${coffee_roast.coffee_roast_id}`} 
            coffee_roast={coffee_roast} onRowClick={() => {}} refreshCoffeeRoasts={() => {}} user_preferences={user_preferences} />
        ))}
      </div>
    </div>
  );
}

function CoffeeBagWrapper() {
  const {coffee_bag_id} = useParams();

  return (
    <LoadingContainer url={`/coffee/bags/${coffee_bag_id}`}>
      <CoffeeBag coffee_bag={DEFAULT_COFFEE_BAG} coffee_roasts={[]} user_preferences={DEFAULT_USER_PREFERENCES} />
    </LoadingContainer>
  );
}

export default CoffeeBagWrapper;
