// Components
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormControlProps } from "react-bootstrap";
import { useEffect, useState } from "react";

interface ServerInputFieldProps extends FormControlProps {
  setValue: React.Dispatch<React.SetStateAction<string>>
  onClick: () => void,
  btnTitle: string,
}
function ServerInputField({value, setValue, type, onClick, btnTitle}:ServerInputFieldProps) {
  const [initialValue, setInitialValue] = useState(value);
  const [valueUpdated, setValueUpdated] = useState(false);

  useEffect(() => {
    setValueUpdated(value !== initialValue);
  }, [value, initialValue]);

  const btnClick = () => {
    setInitialValue(value);
    onClick();
  }

  return (
    <InputGroup>
      <Form.Control
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {valueUpdated && <Button onClick={btnClick}>{btnTitle}</Button>}
    </InputGroup>
  )
}

export default ServerInputField;
