// Components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Text from "../../../components/Text";
import ServerInputField from "../../../components/ServerInputField";
import ServerSelectField from "../../../components/ServerSelectField";
// Hooks
import { useEffect, useState } from "react";
// Types
import { ModalProps } from "../../../@types/modal";
import { CoffeeRoastDB, RoastColor } from "../../../@types/coffee";
// Utils
import { celsiusToFahrenheit } from "../../../utils";
// Constants
import { RoastColors } from "../../../utils/constants";

interface RoastDetailsModalProps extends ModalProps {
  coffee_roast: CoffeeRoastDB,
  postUpdate: (body: any) => void,
  templateLoaded: boolean,
  toggleTemplate: () => void,
}
function RoastDetailsModal({show, onHide, coffee_roast, postUpdate, templateLoaded, toggleTemplate}:RoastDetailsModalProps) {
  const [coffeeIn, setCoffeeIn] = useState('');
  const [coffeeOut, setCoffeeOut] = useState('');
  const [roastColor, setRoastColor] = useState('');
  const [chargeTempC, setChargeTempC] = useState('');

  const [weightLoss, setWeightLoss] = useState(0);
  const [chargeTempF, setChargeTempF] = useState(0);

  useEffect(() => {
    const weight_loss =  1 - (Number(coffeeOut) / Number(coffeeIn));
    setWeightLoss(weight_loss * 100);
  }, [coffeeIn, coffeeOut]);

  useEffect(() => {
    setChargeTempF(celsiusToFahrenheit(Number(chargeTempC)));
  }, [chargeTempC]);

  useEffect(() => {
    const {coffee_in_mg, coffee_out_mg, roast_color, charge_temp_c} = coffee_roast;
    if(coffee_in_mg) {
      setCoffeeIn((coffee_in_mg / 1000).toString());
    }
    if(coffee_out_mg) {
      setCoffeeOut((coffee_out_mg / 1000).toString());
    }
    if(roast_color) {
      setRoastColor(roast_color);
    }
    if(charge_temp_c) {
      setChargeTempC(charge_temp_c.toString());
    }
  }, [coffee_roast]);

  return (
    <Modal show={show} fullscreen={"md-down"} onHide={onHide}>
      <Modal.Header className='border-0 pb-0' closeButton closeVariant='white'>
        <Text size={4}>Roast Details</Text>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end mb-3">
          <Button variant="outline-primary" onClick={toggleTemplate}>
            {templateLoaded ? 'Remove Template Profile' : 'View Template Profile'}
          </Button>
        </div>
        <Form noValidate>
          <div className='row mb-3'>
            <Form.Group className='col pe-1'>
              <Form.Label>Coffee In (g)</Form.Label>
              <ServerInputField
                type='number'
                value={coffeeIn}
                setValue={setCoffeeIn}
                onClick={() => postUpdate({coffee_in_mg: Number(coffeeIn) * 1000})}
                btnTitle="Update"
              />
            </Form.Group>
            <Form.Group className='col ps-1'>
              <Form.Label>Coffee Out (g)</Form.Label>
              <ServerInputField
                type='number'
                value={coffeeOut}
                setValue={setCoffeeOut}
                onClick={() => postUpdate({coffee_out_mg: Number(coffeeOut) * 1000})}
                btnTitle="Update"
              />
            </Form.Group>
          </div>
          <Form.Group className='mb-3'>
            <Form.Label>Charge Temperature (°C)</Form.Label>
            <ServerInputField
              type='number'
              value={chargeTempC}
              setValue={setChargeTempC}
              onClick={() => postUpdate({charge_temp_c: Number(chargeTempC)})}
              btnTitle="Update"
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Roast Color</Form.Label>
            <ServerSelectField
              value={roastColor}
              setValue={setRoastColor}
              onClick={() => postUpdate({roast_color: roastColor})}
              btnTitle="Update"
              options={[
                {value: undefined, title: 'Select a roast color'},
                ...Object.values(RoastColor)
                  .filter(value => typeof value === 'number')
                  .map((roast_color) => ({
                    value: roast_color.toString(), 
                    title: RoastColors[Number(roast_color)]
                  }))
              ]}
            />
          </Form.Group>
        </Form>
        <Text size={3} className="mb-1 mt-3">Roast Info</Text>
        <ListGroup className="mb-3">
          <ListGroup.Item>
            <Text size={5}>Coffee Roast ID: {coffee_roast.coffee_roast_id}</Text>
          </ListGroup.Item>
          <ListGroup.Item>
            <Text size={5}>Weight Loss: {(weightLoss||weightLoss === 0) && weightLoss >= 0 && weightLoss <= 100 ? weightLoss.toFixed(1) : ''}%</Text>
          </ListGroup.Item>
          <ListGroup.Item>
            <Text size={5}>Charge Temperature: {chargeTempF.toFixed(0)}°F</Text>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  ) 
}

export default RoastDetailsModal;
