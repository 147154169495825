import { Breakpoint } from "../@types/breakpoint";
import { RoastColor } from "../@types/coffee";

export const POOL_HANDICAPS = {
  '8-Ball': {
    2: {
      2: 2,
      3: 2,
      4: 2,
      5: 2,
      6: 2,
      7: 2,
    },
    3: {
      2: 3,
      3: 2,
      4: 2,
      5: 2,
      6: 2,
      7: 2,
    },
    4: {
      2: 4,
      3: 3,
      4: 3,
      5: 3,
      6: 3,
      7: 2,
    },
    5: {
      2: 5,
      3: 4,
      4: 4,
      5: 4,
      6: 4,
      7: 3,
    },
    6: {
      2: 6,
      3: 5,
      4: 5,
      5: 5,
      6: 5,
      7: 4,
    },
    7: {
      2: 7,
      3: 6,
      4: 5,
      5: 5,
      6: 5,
      7: 5,
    }
  },
  '9-Ball': {
    1: 14,
    2: 19,
    3: 25,
    4: 31,
    5: 38,
    6: 46,
    7: 55,
    8: 65,
    9: 75,
  },
};


export const RoastColors = {
  [RoastColor.FIRST_CRACK.valueOf()]: '1st Crack',
  [RoastColor.CITY_MINUS.valueOf()]: 'City -',
  [RoastColor.CITY.valueOf()]: 'City',
  [RoastColor.CITY_PLUS.valueOf()]: 'City +',
  [RoastColor.FULL_CITY.valueOf()]: 'Full City',
  [RoastColor.FULL_CITY_PLUS.valueOf()]: 'Full City +',
  [RoastColor.FRENCH.valueOf()]: 'French',
  [RoastColor.BURNT.valueOf()]: 'Burnt',
};

export const COLORS = {
  primary: '#e49e4c',
  secondary: '#5db490',
};

export const BREAKPOINTS = {
  [Breakpoint.XS]: {
    value: Breakpoint.XS,
    class_infix: null,
    min_width: 0,
    max_width: 575,
  },
  [Breakpoint.SM]: {
    value: Breakpoint.SM,
    class_infix: 'sm',
    min_width: 576,
    max_width: 767,
  },
  [Breakpoint.MD]: {
    value: Breakpoint.MD,
    class_infix: 'md',
    min_width: 768,
    max_width: 991,
  },
  [Breakpoint.LG]: {
    value: Breakpoint.LG, 
    class_infix: 'lg',
    min_width: 992,
    max_width: 1199,
  },
  [Breakpoint.XL]: {
    value: Breakpoint.XL,
    class_infix: 'xl',
    min_width: 1200,
    max_width: 1399,
  },
  [Breakpoint.XXL]: {
    value: Breakpoint.XXL, 
    class_infix: 'xxl',
    min_width: 1400,
    max_width: null,
  },
}

