import { createContext, useEffect, useState } from "react";
import { SystemContextType } from "../@types/system";
import api from "../utils/api";

export const SystemContext = createContext<SystemContextType>({offline: false});

interface Props {
  children: React.ReactNode,
};
const SystemProvider: React.FC<Props> = ({ children }) => {
  const [offline, setOffline] = useState(false);
  useEffect(() => {
    const getSystemParams = async () => {
      const {success, error} = await api.get('/');
      if(success) {
        // Do nothing
      } else if(error && error.code === 'ERR_CONNECTION_REFUSED') {
        setOffline(true);
      }
    }
    getSystemParams();
  }, []);

  return (
    <SystemContext.Provider value={{offline, setOffline}}>
      {children}
    </SystemContext.Provider>
  )
}

export default SystemProvider;