// Components
import Text from "../../../components/Text";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Types
import { CoffeeRoastDB, CoffeeUserPreferences } from "../../../@types/coffee";
import { MouseEventHandler, useState } from "react";
// Assets
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { getDisplayTime, timeBetween } from "../../../utils";
import RoastDetails from "./RoastDetails";

interface RoastRowProps {
  coffee_roast: CoffeeRoastDB,
  user_preferences: CoffeeUserPreferences,
  onRowClick: () => void,
  refreshCoffeeRoasts: () => void,
}
function RoastRow({coffee_roast, user_preferences, onRowClick, refreshCoffeeRoasts}:RoastRowProps) {
  const [showDetails, setShowDetails] = useState(false);
    
  const roastDateStr = coffee_roast.end_time ? new Date(coffee_roast.end_time).toLocaleString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }) : 'In-Progress';

  const onDetailsClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    setShowDetails(true);
  }
  const hideDetails = () => setShowDetails(false);

  return (
    <>
      <div className="border-bottom py-2" onClick={onRowClick}>
        <div className="row">
          <div className="col-10 row">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-start">
                <Text size={6} className="mb-2 small" muted>
                  {roastDateStr}
                </Text>
                {!!(coffee_roast.start_time && coffee_roast.end_time) && (<span className="badge bg-secondary text-dark ms-2">
                  {getDisplayTime(timeBetween(coffee_roast.start_time, coffee_roast.end_time))}
                </span>)}
              </div>
              <Text size={6}>{coffee_roast.coffee_bean_name}</Text>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-end align-items-start">
            <Button onClick={onDetailsClick}>
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </div>
        </div>
      </div>
      <Offcanvas show={showDetails} onHide={hideDetails} placement='bottom' responsive="">
        {showDetails && (<RoastDetails coffee_roast={coffee_roast} user_preferences={user_preferences} refreshCoffeeRoasts={refreshCoffeeRoasts} />)}
      </Offcanvas>
    </>
  )
}

export default RoastRow;