import { classNames } from "../utils";

interface Props {
  children: React.ReactNode,
  size: number|string,
  position?: string,
  muted?: boolean,
  bold?: boolean,
  hide?: boolean,
  color?: string,
  onClick?: () => void,
  className?: string,
};
function Text({children, size, position, muted, bold, hide, color, onClick, className = ''}:Props) {
  function handleClick() {
    if(typeof onClick === 'function') {
      onClick();
    }
  }

  return (
    <p className={classNames(['m-0', `h${size}`, {'text-muted': muted}, {'opacity-0': hide}, position ? `text-${position}` : null, color ? `text-${color}` : null, className])}
      onClick={handleClick}>{children}</p>
  )
}

export default Text;