// Components
import ListGroup from "react-bootstrap/ListGroup";
import LoadingContainer from "../../../components/LoadingContainer";
import Text from "../../../components/Text";
import CoffeeBeanModal from "./CoffeeBeanModal";
import Button from "react-bootstrap/Button";
// Hooks
import { useState } from "react";
// Types
import { CoffeeBean } from "../../../@types/coffee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/api";
interface CoffeeBeansProps {
  coffee_beans: CoffeeBean[],
  refreshCoffeeBeans: () => void,
}

function CoffeeBeans({coffee_beans, refreshCoffeeBeans}: CoffeeBeansProps) {
  const navigate = useNavigate();
  const [showBeanModal, setShowBeanModal] = useState(false);

  const startRoast = async (coffee_bean_id: string) => {
    const {data, success} = await api.post('/coffee/roasts', {coffee_bean_id});
    if(success) {
      const {coffee_roast_id} = data;
      navigate(`/coffee/roasts/${coffee_roast_id}`);
    }
  }

  return (
    <>
      <div className="container h-100 d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <Text size={5}>Select Beans</Text>
          <Button onClick={() => setShowBeanModal(true)}>
            <FontAwesomeIcon icon={faPlusSquare} />
          </Button>
        </div>
        {coffee_beans.length ? (
          <ListGroup className="mt-2 mb-3">
            {coffee_beans.map((coffee_bean) => (
              <ListGroup.Item key={`coffee-bean-${coffee_bean.coffee_bean_id}`}
                onClick={() => startRoast(coffee_bean.coffee_bean_id)}
                className="cursor-pointer"
              >
                <Text size={4}>{coffee_bean.name}</Text>
              </ListGroup.Item>
            ))}
          </ListGroup>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <Text size={6} muted>No beans available.</Text>
            </div>
          )}
      </div>
      <CoffeeBeanModal
        show={showBeanModal}
        onHide={() => setShowBeanModal(false)}
        coffee_bean={null}
        onSuccess={refreshCoffeeBeans}
      />
    </>
  );
}

function CoffeeBeansWrapper() {
  // random number to force refresh
  const [rand, setRand] = useState(0);

  const getRand = () => {
    return Math.floor(Math.random() * 100) + 1;
  }

  const refreshCoffeeBeans = () => {
    let new_rand = getRand();
    while(new_rand === rand) {
      new_rand = getRand();
    }
    setRand(new_rand);
  }

  return (
    <LoadingContainer url={`/coffee/beans?r=${rand}`}>
      <CoffeeBeans coffee_beans={[]} refreshCoffeeBeans={refreshCoffeeBeans} />
    </LoadingContainer>
  );
}

export default CoffeeBeansWrapper;
