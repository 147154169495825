import React, { createContext, useCallback, useEffect, useState } from "react";
import {AuthContextType, User} from "../@types/auth";
import api from "../utils/api";
import { useSocket } from "../hooks/useSocket";
import { useSystem } from "../hooks/useSystem";

export const AuthContext = createContext<AuthContextType>({user: null, persist: false, loading: false});

interface Props {
  children: React.ReactNode,
};
const AuthProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist") || 'false')
  );
  const {setOffline} = useSystem();
  const socket = useSocket();

  const refresh = useCallback(async () => {
    const { data, success, error } = await api.get("/auth/refresh");
    if(success) {
      setUser(data.user);
    } else  {
      if(error && error.code === 'ERR_CONNECTION_REFUSED' && setOffline) {
        setOffline(true);
      }
    }
    setLoading(false);
  }, [setOffline]);

  useEffect(() => {
    refresh();
  }, [setOffline, refresh]);

  useEffect(() => {
    if(typeof socket.emit === 'function') {
      socket.emit('user:id', user?.user_id);
    }
  }, [user, socket]);

  // Update local storage with persist
  useEffect(() => {
    const curr = JSON.parse(localStorage.getItem("persist") || 'false');
    if(curr !== persist) {
      localStorage.setItem("persist", persist);
    }
  }, [persist]);

  const signIn: AuthContextType["signIn"] = async ({ username, password, rememberMe }) => {
    const body = {
      username: username.trim(),
      password,
    };

    const {data, success, error} = await api.post('/auth', body);
    if(success) {
      const {user: {username, user_id, acl}} = data;
      setUser({user_id, username, acl});
      setPersist(rememberMe);
    } else {
      setPersist(false);
    }
    return {success, error};
  }

  const signUp: AuthContextType["signUp"] = async ({username, password, code}) => {
    const {data, success, error} = await api.post('/users', {username, password, code: `${code}`.toLowerCase()});
    if(success) {
      const {user: {username, user_id, acl}} = data;
      setUser({user_id, username, acl});
      setPersist(true);
    } else {
      setPersist(false);
    }
    return {success, error};
  }

  const signOut: AuthContextType["signOut"] = async () => {
    const {success} = await api.delete('/auth');
    if(success) {
      setUser(null);
      setPersist(null);
    }
  }
  
  return (
    <AuthContext.Provider 
      value={{
        user,
        setUser,
        persist,
        setPersist,
        loading,
        signIn,
        signUp,
        signOut,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
