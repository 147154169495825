import { useCallback, useEffect, useState } from "react";

interface Props {
  children: string,
}  
let timer: NodeJS.Timer;

function TypeText({children}:Props) {
  const [displayText, setDisplayText] = useState('');
  
  let typeText = useCallback(() => {}, []);
  let deleteText = useCallback(() => {}, []);

  deleteText = useCallback(() => {
    let i = children.length;
    timer = setInterval(() => {
      i--;
      if(i === -1) {
        clearInterval(timer);
        setTimeout(() => {
          typeText();
        }, 500);
      } else {
        setDisplayText((prev) => prev.substring(0, i));
      }
    }, 50);
  }, [children, typeText]);

  typeText = useCallback(() => {
    if(!children.length) {
      return;
    }
    let i = -1;
    timer = setInterval(() => {
      i++;
      if(i === children.length - 1) {
        clearInterval(timer);
        setTimeout(() => {
          deleteText();
        }, 5000);
      }
      if(children[i]) {
        setDisplayText((prev) => prev + children[i]);
      }
    }, 80);
  }, [children, deleteText]);

  useEffect(() => {
    typeText();

    return () => {
      clearInterval(timer);
      setDisplayText('');
    };
  }, [typeText]);
  
  return (
    <p>{displayText}<span className="blink">|</span></p>
  );
}

export default TypeText