import { faCirclePlus, faCircleXmark, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { CoffeeRoastDB } from "../../../@types/coffee";

interface RoastButtonsProps {
  coffee_roast: CoffeeRoastDB,
  postUpdate: (body: any) => void,
  showInput: boolean,
  toggleRoastInput: () => void,
}
function RoastButtons({ coffee_roast, postUpdate, showInput, toggleRoastInput }:RoastButtonsProps) {
  const event_types = [
    {display: 'Dry End', value: 'DRY_END'},
    {display: 'FC Start', value: 'FIRST_CRACK_START'},
    {display: 'FC End', value: 'FIRST_CRACK_END'},
  ];

  return (
    <div className="d-flex justify-content-between mt-2 mb-4">
      <Dropdown
        as={ButtonGroup}
        drop="up"
      > 
        <Dropdown.Toggle variant="info">
          <FontAwesomeIcon icon={faCirclePlus} className="me-2"/>
          <span>Event</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-card z-index-1070 mb-2">
          {event_types.map((event) => (<Dropdown.Item key={`event-${event.value}`} onClick={() => postUpdate({roast_event: {event_type: event.value}})}>{event.display}</Dropdown.Item>))}
        </Dropdown.Menu>
      </Dropdown>
      {showInput 
        ? (
          <Button onClick={toggleRoastInput} variant="outline-primary">
            <FontAwesomeIcon icon={faCircleXmark} className="me-2"/>
            <span>Cancel</span>
          </Button>
        )
        : (
          <Button onClick={toggleRoastInput}>
            <FontAwesomeIcon icon={faCirclePlus} className="me-2"/>
            <span>Input</span>
          </Button>
        )
      }
      {coffee_roast.start_time ? (
        <Button className="py-2 px-3"
          variant="danger"
          onClick={() => {
            const now = new Date().toISOString();
            postUpdate({end_time: now});
          }}
        >
          <FontAwesomeIcon icon={faStopwatch} className="me-2" size="lg"/>
          <span>End</span>
        </Button>
      ) : (
        <Button className="py-2 px-3"
          variant="secondary"
          onClick={() => {
            const now = new Date().toISOString();
            postUpdate({start_time: now});
          }}  
        >
          <FontAwesomeIcon icon={faStopwatch} className="me-2" size="lg"/>
          <span>Start</span>
        </Button>
      )}
    </div>
  )
}

export default RoastButtons;
