import { RoastInput as RoastInputType, RoastInputUnits } from "../../../@types/coffee";
// Components
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { celsiusToFahrenheit, getDisplayTime, timeBetween } from "../../../utils";
import { ReactNode } from "react";

interface RoastTimelineProps {
  start_time: string|null,
  end_time: string|null,
  history: RoastInputType[],
}
function RoastTimeline({start_time, end_time, history}:RoastTimelineProps) {
  if(!start_time) {
    return <div className="flex-fill" />
  }

  return (
    <div className="max-h-400px overflow-scroll no-scrollbar">
      <Timeline position="right" sx={{p: 0}}>
        <TimelineRow title="Start Roast" time="00:00" />
        {history.map((input, idx) => (
          <TimelineRow key={`input-value-${idx}`} time={getDisplayTime(timeBetween(start_time, input.time))}>
            <RoastInput input={input} />
          </TimelineRow>
        ))}
        {!!end_time && (
          <TimelineRow time={getDisplayTime(timeBetween(start_time, end_time))} title="End Roast" showConnector={false} />
        )}
      </Timeline>
    </div>
  )
}

function RoastInput({input}:{input: RoastInputType}) {
  return (
    <div>
      <span>{input.units === RoastInputUnits.DEGREES_CELSIUS ? celsiusToFahrenheit(input.value).toFixed(1) : input.value}</span>{input.units === RoastInputUnits.DEGREES_CELSIUS ? '°F' : ' W'}
    </div>
  )
}

interface TimelineRowProps {
  children?: ReactNode,
  title?: string,
  time: string,
  showConnector?: boolean,
}
function TimelineRow({ children, title, time, showConnector = true }:TimelineRowProps) {
  return (
    <TimelineItem>
      <TimelineOppositeContent className="d-block d-md-none">
        <span className="small">
          {time}
        </span>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot variant="outlined" />
        {showConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
      <div>
          <span className="small d-none d-md-block">
            {time}
          </span>
          <div>
            {title || children}
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

export default RoastTimeline;
