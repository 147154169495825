import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Text from "../../../components/Text";
import { useEffect, useState } from "react";
import { TemperatureUnits } from "../../../@types/coffee";
import api from "../../../utils/api";
import ServerSelectField from "../../../components/ServerSelectField";

export default function CoffeePreferences() {
  const [displayUnits, setDisplayUnits] = useState<TemperatureUnits>('DEGREES_FAHRENHEIT');
  const [inputUnits, setInputUnits] = useState<TemperatureUnits>('DEGREES_CELSIUS');
  const [rightAxis, setRightAxis] = useState('');
  const [leftAxis, setLeftAxis] = useState('');
  
  useEffect(() => {
    const getUserPreferences = async () => {
      const {data, success} = await api.get('/coffee/preferences');
      if(success) {
        const {user_preferences: {
          temperature_display_units, 
          temperature_input_units,
          roast_profile_left_axis,
          roast_profile_right_axis
        }} = data;
        setDisplayUnits(temperature_display_units);
        setInputUnits(temperature_input_units);
        setRightAxis(roast_profile_right_axis);
        setLeftAxis(roast_profile_left_axis);
      }
    }
    getUserPreferences();
  }, []);

  const postPreferencesUpdate = async (body: object) => {
    await api.post('/coffee/preferences', body);
  }

  const updateDisplayUnits = (units: TemperatureUnits) => {
    setDisplayUnits(units);
    postPreferencesUpdate({temperature_display_units: units});
  }

  const updateInputUnits = (units: TemperatureUnits) => {
    setInputUnits(units);
    postPreferencesUpdate({temperature_input_units: units});
  }

  const updateRightAxis = () => {
    postPreferencesUpdate({roast_profile_right_axis: rightAxis})
  }

  const updateLeftAxis = () => {
    postPreferencesUpdate({roast_profile_left_axis: leftAxis});
  }

  return (
    <div className="my-3 p-3 rounded border">
      <Text size={5}>Coffee</Text>
      <Form className="my-2">
        <Form.Group className="mb-3">
          <Form.Label>Display Units</Form.Label>
          <ButtonGroup className="w-100">
            <Button 
              onClick={() => updateDisplayUnits('DEGREES_CELSIUS')}
              variant={displayUnits === 'DEGREES_CELSIUS' ? 'primary' : 'outline-primary'}>Celsius</Button>
            <Button 
              onClick={() => updateDisplayUnits('DEGREES_FAHRENHEIT')}
              variant={displayUnits === 'DEGREES_FAHRENHEIT' ? 'primary' : 'outline-primary'}>Fahrenheit</Button>
          </ButtonGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Input Units</Form.Label>
          <ButtonGroup className="w-100">
            <Button 
              onClick={() => updateInputUnits('DEGREES_CELSIUS')}
              variant={inputUnits === 'DEGREES_CELSIUS' ? 'primary' : 'outline-primary'}>Celsius</Button>
            <Button 
              onClick={() => updateInputUnits('DEGREES_FAHRENHEIT')}
              variant={inputUnits === 'DEGREES_FAHRENHEIT' ? 'primary' : 'outline-primary'}>Fahrenheit</Button>
          </ButtonGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Roast Profile Left Axis</Form.Label>
          <ServerSelectField
            value={leftAxis}
            setValue={setLeftAxis}
            onClick={updateLeftAxis}
            btnTitle="Update"
            options={[
              {value: 'BEAN_TEMP', title: 'Bean Temperature'},
              {value: 'POWER_DRAW', title: 'Power Draw'},
              {value: 'ROR', title: 'Rate of Rise'},
            ]}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Roast Profile Right Axis</Form.Label>
          <ServerSelectField
            value={rightAxis}
            setValue={setRightAxis}
            onClick={updateRightAxis}
            btnTitle="Update"
            options={[
              {value: 'BEAN_TEMP', title: 'Bean Temperature'},
              {value: 'POWER_DRAW', title: 'Power Draw'},
              {value: 'ROR', title: 'Rate of Rise'},
            ]}
          />
        </Form.Group>
      </Form>
    </div>
  )
}