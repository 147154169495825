export interface CoffeeBean {
  coffee_bean_id: string,
  name: string,
  description: string,
  purchase_url: string,
  purchase_price: number,
  origin: string,
  region: string,
  processing: string,
};

export enum RoastInputType {
  BEAN_TEMP = 'BEAN_TEMP',
  POWER_DRAW = 'POWER_DRAW',
}

export enum RoastInputUnits {
  DEGREES_CELSIUS = 'DEGREES_CELSIUS',
  DEGREES_FAHRENHEIT = 'DEGREES_FAHRENHEIT',
  WATTS = 'WATTS',
}

export interface CoffeeRoast {
  coffee_bean_id: string,
  user_id: string,
};

export interface RoastInput {
  input_id: string,
  value: number,
  type: RoastInputType,
  units: RoastInputUnits,
  time: string,
  seconds: number,
}

export interface EventHistory {
  event_id: string,
  type: string,
  time: string,
  seconds: number,
}

export interface CoffeeRoastDB extends CoffeeRoast {
  coffee_roast_id: string,
  coffee_bean_name: string,
  coffee_in_mg: number|null,
  coffee_out_mg: number|null,
  charge_temp_c: number|null,
  roast_color: string|null,
  start_time: string|null,
  first_crack: string|null,
  start_cooling: string|null,
  end_time: string|null,
  end_seconds: number|null,
  input_history: RoastInput[],
  event_history: EventHistory[],
  bag_code: string|null,
};

export const DEFAULT_COFFEE_ROAST = {
  coffee_roast_id: '',
  coffee_bean_id: '',
  user_id: '',
  coffee_bean_name: '',
  coffee_in_mg: null,
  coffee_out_mg: null,
  roast_color: null,
  charge_temp_c: null,
  start_time: null,
  first_crack: null,
  start_cooling: null,
  end_time: null,
  end_seconds: null,
  input_history: [],
  event_history: [],
  bag_code: null,
};

export interface CoffeeBagDB {
  coffee_bag_id: string,
  start_time: string,
  end_time: string,
  bag_code: string,
  coffee_bean_id: string,
  bean_name: string,
  bean_origin: string,
  bean_region: string,
  bean_description: string,
  num_roasts: number,
  coffee_roast_ids: string[],
  avg_roast_color: number,
  mg: number,
}

export const DEFAULT_COFFEE_BAG = {
  coffee_bag_id: '',
  start_time: '',
  end_time: '',
  bag_code: '',
  coffee_bean_id: '',
  bean_name: '',
  bean_origin: '',
  bean_region: '',
  bean_description: '',
  num_roasts: 0,
  coffee_roast_ids: [],
  avg_roast_color: 0,
  mg: 0,
};

export interface CoffeeStatsDB {
  num_roasts: number,
  num_beans: number,
  num_bags: number,
};

export const DEFAULT_COFFEE_STATS = {
  num_roasts: 0,
  num_beans: 0,
  num_bags: 0,
};

export enum RoastColor {
  FIRST_CRACK = 95,
  CITY_MINUS = 85,
  CITY = 75,
  CITY_PLUS = 65,
  FULL_CITY = 55,
  FULL_CITY_PLUS = 45,
  FRENCH = 35,
  BURNT = 25
};

export type TemperatureUnits = 'DEGREES_CELSIUS'|'DEGREES_FAHRENHEIT';
export type ProfileAxis = 'POWER_DRAW'|'BEAN_TEMP'|'ROR';

export interface CoffeeUserPreferences {
  user_id: string,
  template_roast_id: string,
  temperature_display_units: TemperatureUnits,
  temperature_input_units: TemperatureUnits,
  roast_profile_left_axis: ProfileAxis, 
  roast_profile_right_axis: ProfileAxis,
}

export const DEFAULT_USER_PREFERENCES: CoffeeUserPreferences = {
  user_id: '',
  template_roast_id: '',
  temperature_display_units: 'DEGREES_FAHRENHEIT',
  temperature_input_units: 'DEGREES_CELSIUS',
  roast_profile_left_axis: 'POWER_DRAW',
  roast_profile_right_axis: 'BEAN_TEMP',
}
