// Components
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
// Types
import { CoffeeBagDB } from "../../../@types/coffee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface BagRowProps {
  coffee_bag: CoffeeBagDB
}
function BagRow({ coffee_bag }:BagRowProps) {
  const navigate = useNavigate();

  return (
    <ListGroupItem className="d-flex justify-content-between align-items-center"
      onClick={() => navigate(`/coffee/bags/${coffee_bag.coffee_bag_id}`)}
    >
      <span>
        {coffee_bag.bag_code}
      </span>
      <Button variant="link" className="p-0">
        <FontAwesomeIcon icon={faChevronRight}/>
      </Button>
    </ListGroupItem>
  )
}

export default BagRow;
