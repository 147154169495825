// Components
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Types
import { RoastInputType } from "../../../@types/coffee";
// Utils
import { classNames } from "../../../utils";
// Hooks
import { useEffect, useState } from "react";
// Assets
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "../../../hooks/useAlert";

interface RoastInputProps {
  show: boolean,
  onSubmit: ({value, type}: {value: number, type: RoastInputType}) => void,
}
function RoastInput({show, onSubmit}:RoastInputProps) {
  const alertManager =useAlert();

  const [value, setValue] = useState('');
  const [type, setType] = useState('°C');

  useEffect(() => {
    setValue('');
  }, [show]);

  const submitInput = () => {
    const numericValue = Number(value);
    const inputType = type === 'W' ? RoastInputType.POWER_DRAW : RoastInputType.BEAN_TEMP;
    
    if(inputType === RoastInputType.BEAN_TEMP && numericValue > 235) {
      if(typeof alertManager.addAlert === 'function') {
        alertManager.addAlert({type: 'warning', message: 'Cannot post temperature values above 235°C', timeout: 3000});
      }
      return;
    }

    onSubmit({value: numericValue, type: inputType});
  }

  const fractionDigits = 2;
  const appendDigit = (digit: number) => {
    if(value.includes('.') && value.substring(value.indexOf('.')).length > fractionDigits) {
      return;
    }
    setValue(`${value}${digit}`);
  }

  const appendDecimalPoint = () => {
    if(value.includes('.')) {
      return;
    }
    setValue(`${value}.`);
  }

  const createBtns = (start: number, end: number) => Array(end - start + 1).fill(0).map((_num, idx) => ({
    display: `${idx + start}`,
    cols: 1,
    variant: 'tertiary',
    action: () => appendDigit(idx+start),
  }));

  const numRows = 4;
  const numCols = 4;

  const numpadButtons = [
    ...createBtns(7, 9),
    {display: 'Back', cols: 1, variant: 'outline-tertiary', action: () => setValue(value.slice(0, value.length-1))},
    ...createBtns(4, 6),
    {display: 'W', cols: 1, variant: type === 'W' ? 'primary' : 'outline-primary', action: () => setType('W')},
    ...createBtns(1, 3),
    {display: '°C', cols: 1, variant: type === '°C' ? 'primary' : 'outline-primary', action: () => setType('°C')},
    {display: '0', cols: 2, variant: 'tertiary', action: () => appendDigit(0)},
    {display: '•', cols: 1, variant: 'tertiary', action: appendDecimalPoint},
    {display: 'Submit', cols: 1, variant: 'primary', action: submitInput}
  ].reduce<{
    display: string, 
    cols: number, 
    padLeft: boolean, 
    padRight: boolean, 
    padTop: boolean, 
    padBottom: boolean, 
    width: number, 
    idx: number, 
    variant: string, 
    action: () => void,
  }[]>((btns, btn, idx) => {
    const {cols} = btn;
    const prevIdx = idx === 0 ? -1 : btns[idx - 1].idx;
    const startIdx = prevIdx + 1;
    const endIdx = prevIdx + cols;
    const padLeft = startIdx % numCols !== 0;
    const padRight = endIdx % numCols !== numCols - 1;
    const padTop = startIdx >= numCols;
    const padBottom = startIdx < numCols * (numRows - 1);
    const width = (100 / numCols) * cols; 

    btns.push({
      ...btn,
      idx: endIdx,
      padLeft,
      padRight,
      padTop,
      padBottom,
      width,
    });

    return btns;
  }, []);

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label className="h6">Input Value</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            className="form-control-lg text-end"
            type="text"
            value={value}
            readOnly
          />
          <InputGroup.Text className="px-3">{type}</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <div className="d-flex flex-wrap">
        {numpadButtons.map((button, idx) => {
          const {
            display,
            padLeft, 
            padRight,
            padTop,
            padBottom,
            width,
            variant,
            action,
          } = button;
          return (
            <div key={`numpad-btn-${idx}`}
              className={`h-0 overflow-hidden position-relative w-${width}`}
              style={{paddingTop: '25%'}}
            >
              <div className="position-absolute inset-0" style={{inset: 0}}>
                <div className={classNames(['h-100', {'ps-1': padLeft}, {'pe-1': padRight}, {'pt-1': padTop}, {'pb-1': padBottom}])}>
                  <Button className={classNames(['h-100 w-100', {'text-white': variant === 'tertiary'}])} 
                    variant={variant}
                    onClick={action}>{display === 'Back' ? (
                      <FontAwesomeIcon icon={faArrowLeft}/>
                    ) : display}</Button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Form>
  )
}

export default RoastInput;
