interface Player {
  user_id: string,
  username: string,
};

interface Streaks {
  longest_streak: number,
  current_streak: number,
}

interface PlayerStats {
  total_games: number,
  player_wins: number,
  opponent_wins: number,
  point_differential: number, 
  player_streaks: Streaks,
  opponent_streaks: Streaks,
};

export interface CompareProps {
  player: Player,
  opponent: Player,
  stats: PlayerStats,
  discipline: '9-Ball'|'8-Ball',
  setDiscipline: React.Dispatch<'9-Ball'|'8-Ball'>
};

export const DEFAULT_PLAYER: Player = {
  user_id: '',
  username: '',
};

export const DEFAULT_STATS: PlayerStats = {
  total_games: 0,
  player_wins: 0,
  opponent_wins: 0,
  point_differential: 0.0,
  player_streaks: {
    longest_streak: 0,
    current_streak: 0,
  },
  opponent_streaks: {
    longest_streak: 0,
    current_streak: 0,
  },
};