import { getDisplayTime } from "../../../utils";
import useTimeSince from "../../../hooks/useTimeSince";

interface RoastTimerProps {
  start_time: string|null,
  end_time: string|null,
}
function RoastTimer({start_time, end_time}:RoastTimerProps) {
  const {minutes, seconds} = useTimeSince({start_time, end_time});

  return (
    <div className="d-flex justify-content-center justify-content-md-start">
      <p className="threed threed-sm">{getDisplayTime({minutes, seconds})}</p>
    </div>
  )
}

export default RoastTimer;