import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormSelectProps } from "react-bootstrap";
import { useEffect, useState } from "react";

interface ServerSelectOption {
  value: string|undefined,
  title: string,
}
interface ServerSelectFieldProps extends FormSelectProps {
  setValue: React.Dispatch<React.SetStateAction<string>>,
  onClick: () => void,
  btnTitle: string,
  options: ServerSelectOption[]
}
function ServerSelectField({value, setValue, onClick, btnTitle, options}:ServerSelectFieldProps) {
  const [initialValue, setInitialValue] = useState(value);
  const [valueUpdated, setValueUpdated] = useState(false);

  useEffect(() => {
    if(!initialValue && value) {
      setInitialValue(value);
    }
    setValueUpdated(!!initialValue && `${value}` !== `${initialValue}`);
  }, [value, initialValue]);

  const btnClick = () => {
    setInitialValue(value);
    onClick();
  }

  return (
    <InputGroup>
      <Form.Select
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map(({value, title}, idx) => (
          <option key={`server-select-${idx}`} value={value}>{title}</option>
        ))}
      </Form.Select>
      {valueUpdated && <Button onClick={btnClick}>{btnTitle}</Button>}
    </InputGroup>
  )
}

export default ServerSelectField;
