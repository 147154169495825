// Components
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Text from "../../../components/Text";
// Types
import { CoffeeRoastDB, CoffeeUserPreferences, DEFAULT_COFFEE_ROAST } from "../../../@types/coffee";
// Hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "../../../hooks/useConfirm";
// Utils
import api from "../../../utils/api";
import RoastProfile from "./RoastProfile";
import { useAlert } from "../../../hooks/useAlert";

interface RoastDetailsProps {
  coffee_roast: CoffeeRoastDB,
  user_preferences: CoffeeUserPreferences,
  refreshCoffeeRoasts: () => void,
}
function RoastDetails({coffee_roast, user_preferences, refreshCoffeeRoasts}:RoastDetailsProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const alertManager = useAlert();

  const [coffeeRoast, setCoffeeRoast] = useState<CoffeeRoastDB>(DEFAULT_COFFEE_ROAST);
  const [code, setCode] = useState('');

  useEffect(() => {
    setCoffeeRoast(coffee_roast);
  }, [coffee_roast]);

  const postCode = async () => {
    const {success, data} = await api.post(`/coffee/roasts/${coffee_roast.coffee_roast_id}`, {code});
    if(success) {
      const {coffee_roast: new_coffee_roast} = data;
      setCoffeeRoast({...new_coffee_roast});
    }
  }

  const setTemplate = async () => {
    const {success} = await api.post(`/coffee/roasts/template`, {coffee_roast_id: coffeeRoast.coffee_roast_id});
    if(success && typeof alertManager.addAlert === 'function') {
      alertManager.addAlert({type: 'success', message: 'Set Template Roast!', timeout: 3000});
    }
  }

  const deleteRoast = async () => {
    const {success} = await api.delete(`/coffee/roasts/${coffee_roast.coffee_roast_id}`);
    if(success) {
      refreshCoffeeRoasts();
    }
  }

  const onDelete = () => {
    if (confirm) confirm(
      () => deleteRoast(), // onConfirm
      () => { }, // onCancel
      'Are you sure?', // header
      'Do you really want to delete this roast? This process cannot be undone.' // body
    );
  };

  return (
    <div className="container d-flex flex-column py-4 h-100">
      <div className="row d-flex align-items-center">
        <div className="order-3 order-md-1 col-12 col-md-4 my-2">
          <Form.Label className="mb-1">{coffeeRoast.bag_code ? 'Update' : 'Assign'} Bag Code</Form.Label>
          <InputGroup className="align-items-center">
            <Form.Control
              type="text"
              placeholder=""
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button variant='primary' onClick={postCode}>
              Bag
            </Button>
          </InputGroup>
        </div>
        <div className="order-2 col-12 col-md-4">
          {!!coffeeRoast.bag_code && (<div className="d-flex flex-column align-items-center">
            <p className="m-0 text-muted text-center">Bag Code</p>
            <Text size={4} bold>{coffeeRoast.bag_code}</Text>
          </div>)}
        </div>
        <div className="order-1 order-md-3 col-12 col-md-4 justify-content-between justify-content-md-end d-flex my-2">
          <Button
            className="me-2 text-white"
            variant="tertiary"
            onClick={() => navigate(`/coffee/roasts/${coffee_roast.coffee_roast_id}`)}>
            View
          </Button>
          <Button
            className="me-2 text-white"
            variant="outline-primary"
            onClick={setTemplate}>
            Template
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
      <div className="order-3">
        <RoastProfile coffee_roast={coffeeRoast} template_roast={null} user_preferences={user_preferences} />
      </div>
    </div>
  );
}

export default RoastDetails;