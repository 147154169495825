// Components
import ListGroup from "react-bootstrap/ListGroup";
import LoadingContainer from "../../../components/LoadingContainer";
import Text from "../../../components/Text";
import BagRow from "../components/BagRow";
import Button from "react-bootstrap/Button";
// Types 
import { CoffeeBagDB } from "../../../@types/coffee";
import { useNavigate } from "react-router-dom";

interface CoffeeBagsProps {
  coffee_bags: CoffeeBagDB[]
}
function CoffeeBags({ coffee_bags }:CoffeeBagsProps) {
  const navigate = useNavigate();

  return (
    <div className="container h-100 d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Text size={4}>Coffee Bags</Text>      
        <Button size="sm" onClick={() => navigate('/coffee/roasts')}>Roasts</Button>
      </div>
      <hr className="my-0" />
      <div className="mt-2 mb-3">
        <ListGroup>
          {coffee_bags.map((coffee_bag) => (
            <BagRow key={`coffee-bag-${coffee_bag.coffee_bag_id}`} 
              coffee_bag={coffee_bag} />
          ))}
        </ListGroup>
      </div>
    </div>
  );
}

function CoffeeBagsWrapper() {
  return (
    <LoadingContainer url={'/coffee/bags'}>
      <CoffeeBags coffee_bags={[]} />
    </LoadingContainer>
  )
}

export default CoffeeBagsWrapper;
