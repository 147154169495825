import { ChartsReferenceLine, LineChart, LineSeriesType } from "@mui/x-charts";
import { CoffeeRoastDB, CoffeeUserPreferences } from "../../../@types/coffee";
import { celsiusToFahrenheit, convertSeconds, getDisplayTime } from "../../../utils";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { Breakpoint } from "../../../@types/breakpoint";
import { MakeOptional } from "@mui/x-charts/internals";
import useTimeSince from "../../../hooks/useTimeSince";
import CustomAnimatedLine from "../../../components/charts/CustomAnimatedLine";

interface RoastProfileProps {
  coffee_roast: CoffeeRoastDB,
  template_roast: CoffeeRoastDB|null,
  user_preferences: CoffeeUserPreferences,
  show_marks?: boolean,
  show_current_time?: boolean,
}

const EVENT_TITLES: {[name: string]: string} = {
  'FIRST_CRACK_START': 'First Crack',
}

function RoastProfile({ coffee_roast, template_roast, user_preferences, show_marks = false, show_current_time = false}:RoastProfileProps) {
  const breakpoint = useBreakpoint();
  const {minutes, seconds} = useTimeSince(coffee_roast);
  const currentSeconds = (minutes*60)+seconds;
  
  const powerHistory: DataPoint[] = coffee_roast.input_history
    .filter(({type}) => type === "POWER_DRAW")
    .map(ih => ({seconds: ih.seconds, value: ih.value}));
  const tempHistory: DataPoint[] = coffee_roast.input_history
    .filter(({type}) => type === "BEAN_TEMP")
    .map(ih => ({seconds: ih.seconds, value: ih.value}));
  const templateHistory: DataPoint[] = (template_roast?.input_history || [])
    .filter(({type}) => type === "BEAN_TEMP")
    .map(ih => ({seconds: ih.seconds, value: ih.value}));
  const rorHistory: DataPoint[] = tempHistory.map(({seconds, value}, idx) => {
    if(idx === 0) {
      return {seconds, value: value / seconds};
    }
    const previous = tempHistory[idx - 1];
    const ror = (value - previous.value) / (seconds - previous.seconds);
    return {seconds, value: ror};
  });
  
  const inputHistory = combineData({
    temp: tempHistory,
    template: templateHistory,
    power: powerHistory,
    ror: rorHistory,
  });
  const endSeconds = Math.max(template_roast?.end_seconds || 0, coffee_roast?.end_seconds || 0) || (60 * 12.5);

  const xData = getSeriesData({input_history: inputHistory, key: 'seconds', start: 0, end: endSeconds});
  const tData = getSeriesData({input_history: inputHistory, key: 'temp', start: Number(coffee_roast.charge_temp_c), end: 'last'});
  const template = getSeriesData({input_history: inputHistory, key: 'template', start: Number(template_roast?.charge_temp_c), end: 0});
  const pData = getSeriesData({input_history: inputHistory, key: 'power', start: 'first', end: 'last'});
  const rData = getSeriesData({input_history: inputHistory, key: 'ror', start: 0, end: 'last'});

  const displayTempFormat = user_preferences.temperature_display_units;
  const POSTFIXES = {
    DEGREES_FAHRENHEIT: '°F',
    DEGREES_CELSIUS: '°C',
  }
  const tempValueFormatter = (value: number|null) => {
    if(value === null) {
      return '';
    }
    const prefix = displayTempFormat === 'DEGREES_CELSIUS' ? value :  celsiusToFahrenheit(value);
    return `${prefix?.toFixed(0)} ${POSTFIXES[displayTempFormat]}`;
  }

  const axes = [user_preferences.roast_profile_left_axis, user_preferences.roast_profile_right_axis];
  const series: MakeOptional<LineSeriesType, "type">[] = [];

  if(axes.includes('POWER_DRAW')){
    const leftAxis = axes.findIndex((a) => a === 'POWER_DRAW') === 0;
    series.push({ 
      id: 'powerData', 
      data: pData, 
      label: 'Power',
      yAxisId: leftAxis ? 'leftAxis' : 'rightAxis', 
      connectNulls: true, 
      valueFormatter: (v) => v === null ? '' : `${v} W`, 
      showMark: false,
    });
  }
  if(axes.includes('BEAN_TEMP')) {
    const leftAxis = axes.findIndex((a) => a === 'BEAN_TEMP') === 0;
    series.push({ 
      id: 'tempData', 
      data: tData, 
      label: 'Temp', 
      yAxisId: leftAxis ? 'leftAxis' : 'rightAxis', 
      connectNulls: true, 
      valueFormatter: tempValueFormatter, 
      showMark: show_marks,
    });
  }
  if(axes.includes('ROR')) {
    const leftAxis = axes.findIndex((a) => a === 'ROR') === 0;
    series.push({ 
      id: 'rorData', 
      data: rData, 
      label: 'ROR', 
      yAxisId: leftAxis ? 'leftAxis' : 'rightAxis', 
      connectNulls: true, 
      showMark: show_marks,
    });
  }

  if(template_roast && axes.includes('BEAN_TEMP')) {
    const leftAxis = axes.findIndex((a) => a === 'BEAN_TEMP') === 0;
    series.push({ 
      id: 'templateData',
      data: template, 
      label: 'Template', 
      yAxisId: leftAxis ? 'leftAxis' : 'rightAxis', 
      connectNulls: true, 
      valueFormatter: tempValueFormatter, 
      showMark: false, 
      color: '#fdb462',
    });
  }

  return (
    <div className="border rounded my-2 p-1">
      <LineChart 
        xAxis={[{ scaleType: 'linear', data: xData, valueFormatter: (v) => getDisplayTime(convertSeconds(v)), max: endSeconds }]}
        yAxis={[{ id: 'leftAxis' }, { id: 'rightAxis' }]}
        series={series}
        height={breakpoint > Breakpoint.SM ? 300 : 250}
        margin={{ left: 45, right: 35, top: 30, bottom: 30 }}
        grid={{ vertical: true, horizontal: true }}
        leftAxis="leftAxis"
        rightAxis="rightAxis"
        onMarkClick={(e, lineItemIdentifier) => {console.log(lineItemIdentifier)}}
        slots={{ line: CustomAnimatedLine }}
        slotProps={{ line: { excludeIds: ['templateData'],  limit: currentSeconds, sxAfter: { strokeDasharray: '10 5' } } as any }}  
      >
        {coffee_roast.event_history.filter(r => Object.keys(EVENT_TITLES).includes(r.type)).map((roast_event) => (
          <ChartsReferenceLine
            key={`roast-event-${roast_event.event_id}`}
            x={roast_event.seconds}
            lineStyle={{ strokeDasharray: '10 5' }}
            labelStyle={{ fontSize: '10' }}
            label={EVENT_TITLES[roast_event.type]}
            labelAlign="middle"
          />
        ))}
        {(show_current_time && !coffee_roast.end_seconds) && <ChartsReferenceLine
          x={currentSeconds}
          lineStyle={{ strokeDasharray: '10 5' }}
          labelStyle={{ fontSize: '10' }}
          label='Now'
          labelAlign="middle"
        />}
      </LineChart>
    </div>
  )
}

export default RoastProfile;

/**
 *                                                                     *\
 *                    __________________ _        _______              * 
 *            |\     /|\__   __/\__   __/( \      (  ____ \            *
 *            | )   ( |   ) (      ) (   | (      | (    \/            *
 *            | |   | |   | |      | |   | |      | (_____             *
 *            | |   | |   | |      | |   | |      (_____  )            *
 *            | |   | |   | |      | |   | |            ) |            *
 *            | (___) |   | |   ___) (___| (____/\/\____) |            *
 *            (_______)   )_(   \_______/(_______/\_______)            *
 *                                                                     *
\*                                                                    **/

interface DataPoint {
  value: number,
  seconds: number,
}
type DataMap<T extends string> = {
  [key in T]: DataPoint[];
};
interface KeyedDataPoint {
  [key: string]: number,
  seconds: number,
};

function combineData<T extends string>(data:DataMap<T>): ({seconds: number}&{[key in T]: number|null})[] {
  // Convert map the data so the value prop of each array item is renamed with the key
  const keyedData = Object.keys(data).reduce<{[key in T]: KeyedDataPoint[]}>((acc, key) => {
    const typedKey = key as T; // Cast the key to T
    acc[typedKey] = data[typedKey].map(obj => ({[typedKey]: obj.value, seconds: obj.seconds}));
    return acc;
  }, {} as {[key in T]: KeyedDataPoint[]});

  const combined = Object.values<KeyedDataPoint[]>(keyedData)
    .flat() // Combine all the arrays into a single array
    .sort((a, b) => a.seconds - b.seconds) // Sort the array by seconds
    .reduce<KeyedDataPoint[]>((acc, cur) => {
      // Combine values with the same seconds
      if(acc.length >= 1 && acc[acc.length - 1].seconds === cur.seconds) {
        acc[acc.length - 1] = {...acc[acc.length - 1], ...cur};
        return acc;
      }
      acc.push(cur);
      return acc;
    }, [])
    .map(obj => ({
      seconds: obj.seconds,
      // Add all keyed values (null if not defined)
      ...Object.keys(data).reduce<{[key in T]: number|null}>((acc, key) => {
        const typedKey = key as T;
        acc[typedKey] = obj[typedKey] || null;
        return acc;
      }, {} as {[key in T]: number|null}),
    }));
  return combined;
}

interface GetSeriesDataParams {
  input_history: ({seconds: number}&{[key: string]: number|null})[], 
  key: string,
  start: number|null|'first',
  end: number|null|'last'
}
function getSeriesData({input_history, key, start, end}:GetSeriesDataParams): (number|null)[] {
  const filteredData = input_history.filter((input) => input[key]);
  let first;
  if(start === 'first') {
    first = filteredData?.[0]?.[key] || null;
  } else {
    first = start;
  }
  let last = end;
  if(end === 'last') {
    last = filteredData.length ? filteredData[filteredData.length - 1][key] : null; 
  } else {
    last = end;
  }
  return [
    first,
    ...input_history.map((input) => input[key]),
    last
  ];
}
