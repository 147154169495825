import { useEffect, useState } from "react";
import api from "../../utils/api";
import Text from "../../components/Text";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from 'react-bootstrap/Dropdown';

export interface ChessRecord {
  win: number,
  loss: number,
  draw: number,
  unknown: number,
};

/* <div className="d-flex col-6 col-md-4 justify-content-between">
    <div className="d-flex bg-success p-2 rounded">{record.win}</div>
    <div className="d-flex bg-info p-2 rounded">{record.draw}</div>
    <div className="d-flex bg-danger p-2 rounded">{record.loss}</div>
  </div> */

function Chess() {
  const [source, setSource] = useState('chess.com');
  const [rating, setRating] = useState(0);
  const [record, setRecord] = useState<ChessRecord>({win: 0, loss: 0, draw: 0, unknown: 0});

  useEffect(() => {
    const getChessStats = async () => {
      const {data, success} = await api.get(`/chess?source=${source}`);
      if(success) {
        const {rating: apiRating, record: apiRecord} = data[source];
        setRecord(apiRecord);
        setRating(apiRating);
      }
    }
    getChessStats();
  }, [source]);

  return (
    <div className="container h-100">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span className="badge rounded-pill bg-secondary fs-6 cursor-pointer">Rapid</span>
        <Dropdown as={ButtonGroup}>
          <Button onClick={() => {}}>{source}</Button>
          <Dropdown.Toggle split />
          <Dropdown.Menu className="bg-card z-index-1070" align={"end"}>
            <Dropdown.Item onClick={() => setSource('chess.com')}>Chess.com</Dropdown.Item>
            <Dropdown.Item onClick={() => setSource('lichess.org')}>lichess.org</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="d-flex flex-column bg-card rounded p-3 mb-3 min-h-150px me-0 me-lg-2">
            <Text size={6}>Current Rating</Text>
            <div className="d-flex flex-fill justify-content-center">
              <h1 className="threed threed-sm">{rating}</h1>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="d-flex flex-column bg-card rounded p-3 mb-3 min-h-150px ms-0 ms-lg-2">
            <Text size={6} className="mb-3">{record.unknown ? 'Games Played' : 'Current record'}</Text>
            <div className="d-flex flex-fill align-items-center">
              {record.unknown 
                ? (
                  <div className="flex-fill d-flex justify-content-center align-items-center">
                    <h1 className="threed threed-sm">{record.unknown}</h1>
                  </div>
                ) 
                : (
                  <div className="flex-fill d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center align-items-center rounded border border-primary p-2 h-100 min-w-65px">
                      <span className="fw-bold">{record.win}</span>
                      <p className="m-0 text-center">Won</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center rounded border border-primary p-2 h-100 min-w-65px">
                      <span className="fw-bold">{record.draw}</span>
                      <p className="m-0 text-center">Drawn</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center rounded border border-primary p-2 h-100 min-w-65px">
                      <span className="fw-bold">{record.loss}</span>
                      <p className="m-0 text-center">Lost</p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chess;
