import { useEffect, useState } from 'react';
import { BREAKPOINTS } from '../utils/constants';
import { Breakpoint } from '../@types/breakpoint';

interface WindowSize {
  width: number,
  height: number,
};
const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<Breakpoint>(Breakpoint.XS);
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    Object.values(BREAKPOINTS).forEach(({value, min_width, max_width}) => {
      if(windowSize.width >= min_width && (!max_width || windowSize.width < max_width)) {
        setBreakPoint(value);
      }
    });

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return breakpoint;
};

export default useBreakpoint;
