// Components
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
// Types
import { ModalProps } from '../../../@types/modal';
import { CoffeeBean } from '../../../@types/coffee';
// Hooks
import { useState } from 'react';
// Utils
import api from '../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

interface CoffeeBeanModalProps extends ModalProps {
  coffee_bean: CoffeeBean|null,
  onSuccess?: () => void,
}
function CoffeeBeanModal({coffee_bean, show, onHide, onSuccess}:CoffeeBeanModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [origin, setOrigin] = useState('');
  const [region, setRegion] = useState('');
  const [processing, setProcessing] = useState('');

  const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
  const [purchaseUrl, setPurchaseUrl] = useState('');
  const [purchasePrice, setPurchasePrice] = useState(0);

  const create = async () => {
    const coffee_bean = {
      name,
      description,
      origin,
      region,
      processing,
      purchase_url: purchaseUrl,
      purchasePrice: purchasePrice,
    };
                                                                                                                                                                      
    const {success} = await api.post('/coffee/beans', {...coffee_bean});
    if(success) {
      if(typeof onSuccess === 'function') {
        onSuccess();
      }
      onHide();
    }
  }

  const update = async () => {
    
  }

  return (
    <Modal show={show} fullscreen={"md-down"} onHide={onHide}>
      <Modal.Header className='border-0 pb-0' closeButton closeVariant='white'>
        <h4 className='text-primary m-0'>{coffee_bean ? 'Update' : 'New'} Coffee Beans</h4>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className='mb-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" value={description} rows={3} onChange={(e) => setDescription(e.target.value)}/>
          </Form.Group>
          <div className="row mb-3">
            <Form.Group className='col pe-2'>
              <Form.Label>Origin</Form.Label>
              <Form.Control 
                type='text'
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='col ps-2'>
              <Form.Label>Region</Form.Label>
              <Form.Control 
                type='text'
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
            </Form.Group>
          </div>
          <Form.Group className='mb-3'>
            <Form.Label>Processing</Form.Label>
            <Form.Select onChange={(e) => setProcessing(e.target.value)}>
              <option>Select processing method</option>
              <option value="Wet Process">Wet Process</option>
              <option value="Dry Process">Dry Process</option>
            </Form.Select>
          </Form.Group>
          {showPurchaseDetails && (
            <div className="row mb-3">
              <Form.Group className='col pe-2'>
                <Form.Label>URL</Form.Label>
                <Form.Control 
                  type='text'
                  value={purchaseUrl}
                  onChange={(e) => setPurchaseUrl(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='col ps-2'>
                <Form.Label>Price</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control 
                    type='number'
                    value={purchasePrice}
                    onChange={(e) => setPurchasePrice(Number(e.target.value))}
                  />            
                </InputGroup>
              </Form.Group>
            </div>
          )}
          <Form.Group className='d-flex justify-content-between mt-1'>
            <Button onClick={() => setShowPurchaseDetails(!showPurchaseDetails)} variant='tertiary' className='text-white'>
              <FontAwesomeIcon icon={showPurchaseDetails ? faMinusCircle : faPlusCircle} className='me-2'/>
              <span>Purchase Details</span>
            </Button>
            <Button onClick={coffee_bean ? update : create}>{coffee_bean ? 'Update' : 'Save'}</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CoffeeBeanModal;
