import { useEffect, useRef, useState } from "react";
import { timeBetween, timeSince } from "../utils";

interface TimeSinceProps {
  start_time: string|null,
  end_time: string|null,
}
const useTimeSince = ({start_time, end_time}:TimeSinceProps) => {
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTimeSince = (time: string|null) => {
    const {minutes, seconds} = timeSince(time); 
    setMinutes(minutes);
    setSeconds(seconds);
  }

  const getTimeBetween = (start: string, end: string) => {
    const {minutes, seconds} = timeBetween(start, end);
    setMinutes(minutes);
    setSeconds(seconds);
  }

  useEffect(() => {
    if(start_time && !end_time) {
      interval.current = setInterval(() => getTimeSince(start_time), 1000);
    } 
    if(start_time && end_time) {
      getTimeBetween(start_time, end_time);
    }
    return () => {
      if(interval.current) clearInterval(interval.current)
    }; 
  }, [start_time, end_time]);

  return {minutes, seconds};
}

export default useTimeSince;
