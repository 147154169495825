// Hooks
import { useParams } from "react-router-dom";
import { useRef, useState } from "react";
// Utils
import Text from "../../../components/Text";
// Components
import LoadingContainer from "../../../components/LoadingContainer";
import { PieChart } from "@mui/x-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
// Models
import { CompareProps, DEFAULT_PLAYER, DEFAULT_STATS } from "./model";
import { COLORS } from "../../../utils/constants";
// Assets
import { faSquare } from "@fortawesome/free-solid-svg-icons";

function Compare({player, opponent, stats, discipline, setDiscipline}:CompareProps) {
  const chartContainer = useRef<HTMLDivElement>(null);

  return (
    <div className="container h-100 d-flex flex-column">
      <div className="d-flex justify-content-between">
        <Text size={2}>Compare</Text>
        <ButtonGroup>
          <Button 
            size="sm"
            onClick={() => setDiscipline('8-Ball')}
            variant={discipline !== '8-Ball' ? 'outline-primary' : 'primary'}>8-Ball</Button>
          <Button 
            size="sm"
            onClick={() => setDiscipline('9-Ball')}
            variant={discipline !== '9-Ball' ? 'outline-primary' : 'primary'}>9-Ball</Button>
        </ButtonGroup>
      </div>
      <hr className="my-2" />
      <div className="row">
        <div className="col-5 d-flex align-items-center text-break">
          <div className="d-flex align-items-center">
            <Text size={5} className="me-2">{player.username}</Text>
            <span className="d-flex align-items-center text-primary">
              <FontAwesomeIcon icon={faSquare}/>
            </span>
          </div>
        </div>
        <div className="col-2 text-center">
          <Text size={4}>vs.</Text>
        </div>
        <div className="col-5 d-flex justify-content-end text-break">
          <div className="d-flex align-items-center">
            <Text size={5} className="me-2">{opponent.username}</Text>
            <span className="d-flex align-items-center text-secondary">
              <FontAwesomeIcon icon={faSquare}/>
            </span>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      {stats.total_games ? (
        <>
          <div className="row my-2">
            <div className="col-4 text-start">
              <Text size={5}>{stats.player_wins}</Text>
            </div>
            <div className="col-4 text-center">
              <Text size={5} muted>Wins</Text>
            </div>
            <div className="col-4 text-end">
              <Text size={5}>{stats.opponent_wins}</Text>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-6" ref={chartContainer}>
              <PieChart
                series={[
                  { data: [{ value: stats.opponent_wins, color: COLORS.secondary }, { value: stats.player_wins, color: COLORS.primary }] },
                ]}
                {...{height: chartContainer.current?.offsetWidth ?? 300, margin: {right: 5, left: 5}}}
              />
            </div>
          </div>
          {discipline === '9-Ball' && (
            <div className="row d-flex align-items-center my-2">
              <div className="col-4 text-start">
                <Text size={5}>{stats.point_differential}</Text>
              </div>
              <div className="col-4 text-center">
                <Text size={5} muted>Point Differential</Text>
              </div>
              <div className="col-4 text-end">
                <Text size={5}>{stats.point_differential*-1}</Text>
              </div>
            </div>
          )}
          <div className="row d-flex align-items-center my-2">
            <div className="col-4 text-start">
              <Text size={5}>{stats.player_streaks.longest_streak}</Text>
            </div>
            <div className="col-4 text-center">
              <Text size={5} muted>Longest Streak</Text>
            </div>
            <div className="col-4 text-end">
              <Text size={5}>{stats.opponent_streaks.longest_streak}</Text>
            </div>
          </div>
          <div className="row d-flex align-items-center my-2">
            <div className="col-4 text-start">
              <Text size={5}>{stats.player_streaks.current_streak}</Text>
            </div>
            <div className="col-4 text-center">
              <Text size={5} muted>Current Streak</Text>
            </div>
            <div className="col-4 text-end">
              <Text size={5}>{stats.opponent_streaks.current_streak}</Text>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Text size={6} muted>No one-on-one {discipline} games played.</Text>
        </div>
      )}
    </div>
  );
}

function CompareWrapper() {
  const [discipline, setDiscipline] = useState<'9-Ball'|'8-Ball'>('9-Ball');
  const { user_id } = useParams();

  return (
    <LoadingContainer url={`/pool/compare/${user_id}?discipline=${discipline}`}>
      <Compare player={DEFAULT_PLAYER} opponent={DEFAULT_PLAYER} stats={DEFAULT_STATS} discipline={discipline} setDiscipline={setDiscipline}/>
    </LoadingContainer>
  );
}

export default CompareWrapper;
