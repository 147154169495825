import Button from "react-bootstrap/Button";
import { ReactComponent as SanteeCoffeeLogo } from "../../assets/img/SanteeCoffeeRoasters.svg";

import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useState } from "react";

function Coffee() {
  const navigate = useNavigate();
  const [bagCode, setBagCode] = useState('');

  return (
    <div className="container h-75 d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center">
        <SanteeCoffeeLogo style={{width: "65%"}} />
      </div>
      <Form.Label>Enter your bag code:</Form.Label>
      <Form.Control
        type="text"
        value={bagCode}
        onChange={(e) => setBagCode(e.target.value)}
      />
      <Button className="mt-2 w-100" onClick={() => navigate(`/coffee/bags/code/${bagCode}`)} disabled={!bagCode}>Find</Button>
    </div>
  );
}

export default Coffee;
